<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row class="py-3">
          <div class="p-4">
            <basic-input
              label="Mã bài học"
              placeholder="Nhập mã bài học"
              name="levelCode"
              class="quize-select"
              :value.sync="filters.lessonCode"
              @keyup.native.enter="loadData"
            />
          </div>
          <div class="p-4">
            <basic-select
              label="Cấp độ liên quan"
              placeholder="--- Chọn cấp độ liên quan ---"
              multiple
              name="level"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              class="quize-select"
              track-by="value"
              :options="levels"
              :value.sync="filters.lessonLevels"
              @update:value="selectLevel"
            />
          </div>
          <div class="p-4">
            <basic-select
              label="Bài học liên quan"
              placeholder="--- Chọn bài học liên quan ---"
              name="coach"
              multiple
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              class="quize-select"
              :options="lessons"
              :value.sync="filters.selectedLessions"
              @update:value="selectLesson"
            />
          </div>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-550">
            <b-container class="p-0">
              <b-row>
                <b-col cols="6">
                  <basic-input
                    label="Mã câu hỏi"
                    placeholder="Nhập mã câu hỏi"
                    name="code"
                    :value.sync="filters.code"
                  ></basic-input>
                </b-col>
                <b-col cols="6">
                  <basic-input
                    label="Tên câu hỏi"
                    placeholder="Nhập tên câu hỏi"
                    name="name"
                    :value.sync="filters.name"
                  ></basic-input>
                </b-col>
                <b-col cols="6">
                  <basic-input
                    label="Mã bài học"
                    placeholder="Nhập mã bài học"
                    name="levelCode"
                    class="quize-select"
                    :value.sync="filters.lessonCode"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Loại câu hỏi"
                    placeholder="--- Chọn loại câu hỏi ---"
                    name="type"
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :options="quizTypes"
                    :value.sync="filters.type"
                    @update:value="setTypeId"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Cấp độ liên quan"
                    placeholder="--- Chọn cấp độ liên quan ---"
                    name="level"
                    :solid="false"
                    multiple
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :options="levels"
                    :value.sync="filters.lessonLevels"
                    @update:value="setlessonLevels"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Bài học"
                    placeholder="--- Chọn bài học liên quan ---"
                    name="coach"
                    :solid="false"
                    multiple
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :options="lessons"
                    :value.sync="filters.selectedLessions"
                    @update:value="setselectedLessions"
                  />
                </b-col>
                <b-col cols="6">
                  <basic-select
                    label="Trạng thái"
                    placeholder="--- Chọn trạng thái ---"
                    name="coach"
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :options="statuses"
                    :value.sync="filters.status"
                    @update:value="setStatus"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success"
          type="button"
          @click="createQuize"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm câu hỏi mới
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td>
            {{ item.code }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.name }}
            </div>
          </td>
          <td style="width: 20px">
            <action-dropdown
              :value="item"
              :show_copy="false"
              :show_view="false"
              :show_delete="!showBtnActive(item) && isWritePermission"
              :show_edit="false"
              :boundary="data.length > 2 ? 'scrollParent' : 'window'"
              @delete="showPopupChangeStatus(item.id, 0)"
            >
              <template>
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="viewQuize(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/eye.svg"
                      />
                    </span>
                    <span class="navi-text text-primary">Xem câu hỏi</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="isWritePermission"
                ></b-dropdown-divider>
                <template v-if="isWritePermission">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="editQuize(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                      </span>
                      <span class="navi-text text-primary"
                        >Chỉnh sửa câu hỏi</span
                      >
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                    <a
                      class="navi-link text-primary"
                      @click.stop="copyQuize(item)"
                    >
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/copy.svg"
                        />
                      </span>
                      <span class="navi-text text-primary"
                        >Sao chép câu hỏi</span
                      >
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-text
                    v-if="showBtnActive(item)"
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="showPopupChangeStatus(item.id, 1)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/power.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Active</span>
                    </a>
                  </b-dropdown-text>
                </template>
              </template>
            </action-dropdown>
          </td>
          <td>
            {{ getQuizType(item.type) }}
          </td>
          <td class="text-center">
            {{ item.lessonLevel }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.lesson }}
            </div>
          </td>
          <td>
            <div class="status" :class="getStatus(item.status)">
              {{ getStatus(item.status) }}
            </div>
          </td>
        </template>
      </template-table>
    </Wrapper>
    <ModalQuize
      v-if="showModal"
      :type="typeModal"
      :id="quizeId"
      @reset="resetModal"
      @submit="submit"
      @change-status="loadData"
    />
  </div>
</template>
<script>
import { SET_MODAL } from '@/core/services/store/context.module';
import { MODAL_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
import {
  GET_QUIZES,
  GET_DATA_PREPARE_LIST,
  CHANGE_STATUS,
} from '@/core/services/store/quize.module';
const { mapActions } = createNamespacedHelpers('quize');
export default {
  name: 'Quize',
  components: {
    ModalQuize: () => import('./components/Modal.vue'),
  },
  data() {
    return {
      showModal: false,
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'code',
          label: 'Mã',
          sortable: true,
          style: {
            width: '10%',
          },
        },
        {
          key: 'name',
          label: 'Câu hỏi',
          sortable: true,
          style: {
            width: '25%',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'quizeType',
          label: 'Loại câu hỏi',
          sortable: false,
          style: {
            width: '130px',
          },
        },
        {
          key: 'level',
          label: 'Cấp độ',
          sortable: false,
          style: {
            width: '75px',
          },
          class: 'text-center',
        },
        {
          key: 'lesson',
          label: 'Bài học',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '5%',
          },
        },
      ],
      data: [],
      typeModal: null,
      sort: {
        by: null,
        order: null,
      },
      orderByExtend: {
        by: null,
        order: null,
      },
      filters: {
        code: null,
        name: null,
        type: null,
        lessonLevels: null,
        selectedLessions: null,
        status: null,
        lessonCode: null,
      },
      levels: [],
      lessons: [],
      quizTypes: [],
      quizeId: null,
      statuses: [
        {
          text: 'Draft',
          value: '2',
        },
        {
          text: 'Active',
          value: '1',
        },
        {
          text: 'Inactive',
          value: '0',
        },
      ],
    };
  },
  computed: {
    searchParams() {
      const filter = {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        orderByExtend: this.orderByExtend.by
          ? `${this.orderByExtend.by} ${this.orderByExtend.order}`
          : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        code: this.filters.code?.trim() || null,
        name: this.filters.name?.trim() || null,
        type: this.filters.type?.value || null,
        status: this.filters.status?.value || null,
        lessonCode: this.filters.lessonCode,
      };
      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }

      if (this.filters.lessonLevels?.length) {
        this.filters.lessonLevels.forEach((el) => {
          params.append('lessonLevelIdList', el.value);
        });
      }
      if (this.filters.selectedLessions?.length) {
        this.filters.selectedLessions.forEach((el) => {
          params.append('lessonIdList', el.value);
        });
      }
      return params;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  async created() {
    await Promise.all([this.loadData(), this.getPrepareList()]);
  },
  methods: {
    ...mapActions({ GET_QUIZES, GET_DATA_PREPARE_LIST, CHANGE_STATUS }),
    async loadData() {
      const { data, error } = await this.GET_QUIZES(this.searchParams);
      if (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.data = data.data;
      this.paging.total = data.meta.total;
    },
    async getPrepareList() {
      const { data, error } = await this.GET_DATA_PREPARE_LIST();

      if (error) return;

      this.levels = data.lessonLevels;
      this.lessons = data.lessons;
      this.quizTypes = data.quizTypes;
    },
    sortRequest(val) {
      if (val.column === 'lesson') {
        this.orderByExtend.by = val.column;
        this.orderByExtend.order = val.order;
        this.sort.by = null;
        this.sort.order = null;
      } else {
        this.sort.by = val.column;
        this.sort.order = val.order;
        this.orderByExtend.by = null;
        this.orderByExtend.order = null;
      }
      this.loadData();
    },
    editQuize(item) {
      this.typeModal = MODAL_TYPE.EDIT;
      this.quizeId = item.id;
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    viewQuize(item) {
      this.typeModal = MODAL_TYPE.DETAIL;
      this.quizeId = item.id;
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    copyQuize(item) {
      this.typeModal = MODAL_TYPE.COPY;
      this.quizeId = item.id;
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    createQuize() {
      this.typeModal = MODAL_TYPE.CREATE;
      this.showModal = true;
      this.$store.commit(`context/${SET_MODAL}`, true);
    },
    resetModal() {
      this.showModal = false;
      this.quizeId = null;
      this.$store.commit(`context/${SET_MODAL}`, false);
    },
    submit() {
      this.resetModal();
      this.loadData();
    },
    resetPaging() {
      this.paging.page = 1;
      this.loadData();
    },
    resetRequest() {
      this.filters = {
        code: null,
        name: null,
        type: null,
        lessonLevels: null,
        selectedLessions: null,
        status: null,
      };
      this.resetPaging();
      document.body.click();
    },
    selectLevel(value) {
      this.setlessonLevels(value);
      this.resetPaging();
    },
    selectLesson(value) {
      this.setselectedLessions(value);
      this.resetPaging();
    },
    setlessonLevels(value) {
      this.filters.lessonLevels = this.selectMultipleItems(value, this.levels);
    },
    setselectedLessions(value) {
      this.filters.selectedLessions = this.selectMultipleItems(
        value,
        this.lessons,
      );
    },
    setTypeId(value) {
      this.filters.type = this.selectItem(value, this.quizTypes);
    },
    setStatus(value) {
      this.filters.status = this.selectItem(value, this.statuses);
    },
    selectItem(value, array) {
      return value ? array.find((el) => el.value === value) : null;
    },
    selectMultipleItems(value, array) {
      const newArray = [];

      if (!value || !value.length) return null;

      value.forEach((el) => {
        const item = array.find((e) => e.value === el);

        if (item) {
          newArray.push(item);
        }
      });

      return newArray;
    },
    searchRequest() {
      this.resetPaging();
      document.body.click();
    },
    showBtnActive(item) {
      if ([0, 2].includes(item.status)) return true;
      return false;
    },
    showPopupChangeStatus(id, status) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          status ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(id, status);
          }
        }.bind(this),
      );
    },
    async changeStatus(id, status) {
      this.$store.commit('context/setLoading', true);
      const params = {
        id,
        state: status,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhật thành công',
        });
        this.loadData();
      }
      this.$store.commit('context/setLoading', false);
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'inactive';
        case 1:
          return 'active';

        default:
          return 'draft';
      }
    },
    getQuizType(type) {
      if (type === 1) return 'Single choice';
      return 'Multiple choices';
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-550 {
  min-width: 550px;
}

.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
.quize-select {
  .multiselect {
    min-width: 220px;
  }
}
</style>
